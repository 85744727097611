import React from 'react';

import {
  SellingPointsContainer,
  SellingPointsWrapper,
} from './styled-components';

import { sellingPoints } from './sellingPoints';

const SellingPoints = () => {
  return (
    <SellingPointsWrapper>
      {sellingPoints.map((point, idx) => (
        <SellingPointsContainer key={idx}>
          <img src={point.img} alt={point.header} />
          <h1>{point.header}</h1>
          {Array.isArray(point.body)
            ? point.body.map((line) => (
                <React.Fragment key={line}>
                  {line}
                  <br />
                </React.Fragment>
              ))
            : point.body}
        </SellingPointsContainer>
      ))}
    </SellingPointsWrapper>
  );
};

export default SellingPoints;
