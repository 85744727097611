import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './testimonials.css';

import { colors } from '../../styles';

import { testimonials } from './data';

const Testimonials = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 25,
        paddingTop: 0,
      }}
    >
      <Carousel
        showArrows
        infiniteLoop
        autoPlay
        showThumbs={false}
        showStatus={false}
        interval={6100}
      >
        {testimonials.map((testimonial) => (
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 15,
            }}
          >
            <img src={testimonial.image} alt="writer/author" />
            <div className="myCarousel">
              <h3>{testimonial.name}</h3>
              {testimonial?.subText && <h4>{testimonial.subText}</h4>}
              <p>{testimonial.quote}</p>

              <span>
                <a
                  style={{
                    color: colors.limeGreen,
                  }}
                  href={testimonial.file}
                  target="_blank"
                  rel="noreferrer"
                >
                  View the Full Testimonial
                </a>
              </span>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Testimonials;
