import styled, { css } from 'styled-components';
import { colors } from '../../styles';
import { Link as RouterLink } from 'react-router-dom';

import mountains from '../../images/logoMountains.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  padding: 85px 0 75px 0;
  background-color: ${colors.gray};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  ::before {
    content: '';
    position: absolute;

    bottom: -50px;
    top: 0;
    left: 0;
    right: 0;

    background-repeat: no-repeat;
    background-position: 50% 100%;
    background-size: 75% auto;
    opacity: 0.1;
    background-image: url(${mountains});
    @media (max-width: 900px) {
      bottom: 0;
    }
  }
`;

const Header = styled.h1`
  color: ${colors.limeGreen};
  font-size: 60px;
  margin-top: 0;
  z-index: 1;
  padding: 0 15px;

  @media (max-width: 600px) {
    font-size: 40px;
  }
`;

const SubHeader = styled.h2`
  font-size: 20px;
  color: ${colors.cream};
  font-weight: 300;
  margin: 0;
  z-index: 1;
  text-align: center;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 600px) {
    font-size: 15px;
  }
`;

const LinkWrapper = styled.div`
  background-color: ${colors.limeGreen};
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    > svg > g > path {
      stroke: ${colors.limeGreen};
    }

    background-color: ${({ inverted }) =>
      inverted ? colors.black : colors.cream};

    ${({ inverted }) =>
      inverted &&
      css`
        > * {
          color: ${colors.limeGreen};
        }
      `}
  }
`;

const ExternalLink = styled.a`
  color: ${({ color }) => colors[color] || colors.gray};
  padding: 15px 25px;
  font-size: 20px;
  text-decoration: none;

  :hover {
    color: ${({ color }) => color && colors.cream};
  }

  @media (max-width: 600px) {
    font-size: 15px;
    padding: 10px 15px;
  }
`;

const Link = styled(RouterLink)`
  color: ${({ color }) => colors[color] || colors.gray};
  padding: 15px 25px;
  font-size: 20px;
  text-decoration: none;

  :hover {
    color: ${({ color }) => color && colors.cream};
  }

  @media (max-width: 600px) {
    font-size: 15px;
    padding: 10px 15px;
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  padding-top: 65px;
  z-index: 1;
`;

const SellingPointsContainer = styled.div`
  > h1 {
    font-weight: 300;
    font-size: 24px;
    color: ${colors.black};
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-color: ${colors.limeGreen};
  }
  font-size: 18px;
  color: ${colors.gray};
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  -webkit-flex: 0 1 33%;
  flex-basis: calc(33% - 40px);

  @media (max-width: 900px) {
    flex-basis: calc(50% - 40px);
  }
  @media (max-width: 600px) {
    flex-basis: calc(100% - 40px);
  }
`;

const SellingPointsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  flex-wrap: wrap;
  margin: 40px;
  max-width: 90%;
`;

const SectionWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  flex-direction: column;
  background-color: ${colors.cream};
  ${({ invert }) =>
    invert &&
    css`
      background-color: ${colors.black};
    `}
`;

const SectionTitle = styled.h1`
  font-size: 40px;
  color: ${colors.black};
  text-align: center;
  width: 100%;

  ${({ invert }) =>
    invert &&
    css`
      color: ${colors.cream};
    `}
`;

const NDALogoLink = styled.a.attrs({
  href: 'https://diabetesnv.org/',
  target: '_blank',
})`
  margin-top: 15px;
  background: ${({ src }) => `url(${src})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 187.5px;
  height: 118.5px;
  display: flex;
`;

const Green = styled.span`
  color: ${colors.limeGreen};
  font-weight: bold;
`;

export {
  HeaderWrapper,
  Wrapper,
  Header,
  SubHeader,
  LinkWrapper,
  Link,
  LinksWrapper,
  SellingPointsContainer,
  SellingPointsWrapper,
  NDALogoLink,
  ExternalLink,
  SectionWrapper,
  SectionTitle,
  Green,
};
