import * as React from 'react';

import {
  Wrapper,
  LogoLink,
  LogoSocialWrapper,
  SocialLinkIcon,
  SocialWrapper,
  CopyRight,
  CopyrightLink,
  TOSButton,
} from './styled-components';

import TOS from './TOS.pdf';

import Facebook from '../../images/socialIcons/Facebook.png';
// import LinkedIn from '../../images/socialIcons/LinkedIn.png';
import Instagram from '../../images/socialIcons/Instagram.png';
import Twitter from '../../images/socialIcons/Twitter.png';

// const linkedIn = 'https://google.com';
const facebook = 'https://m.facebook.com/people/CampViews/100083497835355/';
const instagram = 'https://www.instagram.com/campviewsemr/';
const twitter = 'https://twitter.com/campviews';

const Footer = () => {
  return (
    <Wrapper>
      <LogoSocialWrapper>
        <LogoLink to="/">VIEWS EMR</LogoLink>

        {/* link out to terms of service */}
        <TOSButton
          onClick={() => {
            window.open(TOS);
          }}
        >
          Terms of Service
        </TOSButton>
        <SocialWrapper>
          <SocialLinkIcon
            title="Facebook"
            src={Facebook}
            alt="Facebook"
            href={facebook}
          />
          <SocialLinkIcon
            title="Twitter"
            src={Twitter}
            alt="Twitter"
            href={twitter}
          />
          <SocialLinkIcon
            title="Instagram"
            src={Instagram}
            alt="Instagram"
            href={instagram}
          />
          {/* <SocialLinkIcon
            title="LinkedIn"
            src={LinkedIn}
            alt="LinkedIn"
            href={linkedIn}
          /> */}
        </SocialWrapper>
        <CopyRight>
          © 2022 Copyright. All Rights Reserved.{' '}
          <CopyrightLink href="https://diabetesnv.org/">
            Nevada Diabetes Association
          </CopyrightLink>
        </CopyRight>
      </LogoSocialWrapper>
    </Wrapper>
  );
};

export default Footer;
