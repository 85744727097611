import styled from 'styled-components';

import { Link } from 'react-router-dom';

import { colors } from '../../styles';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: ${colors.black};
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const LogoSocialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 24px 18px 24px;
  text-align: center;
`;

export const LogoLink = styled(Link)`
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: ${colors.limeGreen};
  text-decoration: none;
`;

export const SocialWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 17px;
  overflow: visible;
`;

export const SocialLinkIcon = styled.a.attrs({
  target: '_blank',
  rel: 'noreferrer',
})`
  text-decoration: none;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 25px;
  height: 25px;

  &:not(:last-child) {
    margin-right: 13px;
  }
`;

export const CopyRight = styled.p`
  margin: 0;
  margin-top: 17px;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: ${colors.limeGreen};
`;

export const CopyrightLink = styled.a.attrs({
  target: '_blank',
  rel: 'noreferrer',
})`
  text-decoration: none;
  cursor: pointer;
  color: ${colors.limeGreen};

  :hover {
    text-decoration: underline;
    color: ${colors.cream};
  }
`;

export const TOSButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${colors.cream};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  :hover {
    color: ${colors.limeGreen};
    text-decoration: underline;
  }
`;
