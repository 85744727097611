import * as React from 'react';
import toast from 'react-hot-toast';
import axios from 'axios';

import {
  StyledInput,
  FormOverlay,
  Wrapper,
  StyledLabel,
  StyledTextArea,
  StyledSelect,
  StyledButton,
} from './styled-components';

const Contact = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const firstName = data.get('firstName');
    const lastName = data.get('lastName');
    const email = data.get('email');
    const comments = data.get('comments');
    const phone = data.get('phone');
    const dropdown = data.get('dropdown');
    const organization = data.get('organization');
    if (!firstName || !lastName || !email || !phone) {
      toast.error('First Name, Last Name, Email, and Phone are required.');
      return;
    }

    axios
      .post('/contact', {
        firstName,
        lastName,
        email,
        comments,
        phone,
        dropdown,
        organization,
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success('Success!');
          document.getElementById('contactForm').reset();
        } else {
          toast.error('Something went wrong, please try again later!');
        }
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again later!');
      });
  };

  return (
    <Wrapper>
      <FormOverlay>
        <form onSubmit={handleSubmit} id="contactForm">
          <h1>Contact Us</h1>
          <StyledLabel htmlFor="firstName">*First Name:</StyledLabel>
          <br />
          <StyledInput type="text" id="firstName" name="firstName" />
          <br />
          <StyledLabel htmlFor="lastName">*Last Name:</StyledLabel>
          <br />
          <StyledInput type="text" id="lastName" name="lastName" />
          <br />
          <StyledLabel htmlFor="organization">Organization:</StyledLabel>
          <br />
          <StyledInput type="text" id="organization" name="organization" />
          <br />
          <StyledLabel htmlFor="phone">*Phone Number:</StyledLabel>
          <br />
          <StyledInput type="tel" id="phone" name="phone" />
          <br />
          <StyledLabel htmlFor="email">*Email:</StyledLabel>
          <br />
          <StyledInput type="text" id="email" name="email" />
          <br />

          <StyledLabel htmlFor="dropdown">Reason:</StyledLabel>
          <br />
          <StyledSelect id="dropdown" name="dropdown">
            <option value="inquire">Inquire</option>
            <option value="complain">Complain</option>
            <option value="concern">Concern</option>
          </StyledSelect>
          <br />

          <StyledLabel htmlFor="comments">Comments:</StyledLabel>
          <br />
          <StyledTextArea type="text" id="comments" name="comments" />

          <br />

          <StyledButton type="submit" value="Submit">
            Submit
          </StyledButton>
        </form>
      </FormOverlay>
    </Wrapper>
  );
};

export default Contact;
