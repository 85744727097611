import * as React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { Home, Faqs, Contact, Register, Download } from './pages';
import NavBar from './components/NavBar';
import Footer from './components/Footer/Footer';

const App = () => {
  return (
    <BrowserRouter>
      <NavBar />
      <Toaster
        containerStyle={{
          top: 60,
        }}
      />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/register" element={<Register />} />
        <Route path="/download" element={<Download />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
