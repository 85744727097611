import styled from 'styled-components';
import { colors } from '../../styles';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 142px - 50px);

  @media (max-width: 500px) {
    padding-top: 50px;
    height: fit-content;
  }
`;

export const FormOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${colors.black};
  width: 50%;
  height: fit-content;
  overflow: hidden;

  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.5);
  margin: 20px;

  form {
    width: 100%;
  }

  h1 {
    color: ${colors.limeGreen};
    text-align: center;
  }

  @media (max-width: 900px) {
    width: 75%;
  }

  @media (max-width: 500px) {
    width: fill-available;
  }
`;

export const StyledInput = styled.input`
  border: 2px solid ${colors.cream};
  border-radius: 5px;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
  background-color: ${colors.cream};
  color: ${colors.black};
`;

export const StyledLabel = styled.label`
  color: ${colors.limeGreen};
`;

export const StyledTextArea = styled.textarea`
  border: 2px solid ${colors.cream};
  border-radius: 5px;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
  height: 100px;
  background-color: ${colors.cream};
  color: ${colors.black};
`;

export const StyledSelect = styled.select`
  border: 2px solid ${colors.cream};
  border-radius: 5px;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 10px;
  width: calc(100% + 14px);
  height: 29px;
  background-color: ${colors.cream};
  color: ${colors.black};
`;

export const StyledButton = styled.button`
  background-color: ${colors.limeGreen};
  color: ${colors.black};
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: calc(100% + 14px);
  cursor: pointer;
`;
