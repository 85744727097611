import './Download.css';

import google from './google.png';
import apple from './apple.png';

const Download = () => {
  return (
    <body
      style={{
        background: '#000000',
        padding: '100px 20px 40px 20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 'calc(100vh - 142px - 50px)',
      }}
    >
      <div className="profile-card">
        <div className="card shadow-soft border-light text-center">
          <div className="profile-image">
            <img
              src="https://application-logos.s3.amazonaws.com/2b3d7870a7892367a08182f5e9225590.png"
              className="card-img-top"
              alt="logo"
              style={{ borderRadius: '15%!important' }}
            />
          </div>
          <div className="card-body mt-n5">
            <h2 className="card-title">Views EMR</h2>
            <a
              href="https://CampViews.org"
              className="card-subtitle"
              style={{ color: '#000000!important' }}
            >
              CampViews.org
            </a>
            <p className="card-text my-2">
              ViewsEMR is a mobile application and software that provides the
              medical community with a registration system, a participant's
              individual profile and digital log with real-time CGM data, trend
              &amp; reports. The increased efficiency and seamless access to the
              medical data keeps all staff informed in real time.
            </p>
            <div className="card-footer p-1">
              <a
                className="animate-up-1"
                href="https://play.google.com/store/apps/details?id=com.campviews2.app&amp;pcampaignid=web_share"
              >
                <img
                  style={{ width: '160px' }}
                  className="google-play-icon apple-appstore-icon"
                  src={google}
                  alt="google download button"
                />
              </a>
            </div>
            <div className="card-footer p-1">
              <a
                className="animate-up-1"
                href="https://apps.apple.com/us/app/viewsemr/id6446182010"
              >
                <img
                  style={{ width: '160px' }}
                  className="google-play-icon apple-appstore-icon"
                  src={apple}
                  alt="apple download button"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
};

export default Download;
