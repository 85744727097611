import * as React from 'react';

import Testimonials from '../../components/Testimonials';
import SellingPoints from '../../components/SellingPoints';

import {
  HeaderWrapper,
  Wrapper,
  Header,
  SubHeader,
  NDALogoLink,
  SectionWrapper,
  SectionTitle,
  Green,
} from './styled-components';

import nda from '../../images/nda.png';

const Home = () => {
  return (
    <Wrapper>
      <HeaderWrapper>
        <Header>VIEWS EMR</Header>
        <SubHeader style={{ flexDirection: 'row' }}>
          Powered By&nbsp;<Green>CampViews</Green>
        </SubHeader>
        <SubHeader>
          Owned And Operated By The <NDALogoLink src={nda} />
        </SubHeader>
      </HeaderWrapper>
      <SellingPoints />
      <SectionWrapper invert>
        <SectionTitle invert>Testimonials</SectionTitle>
        <Testimonials />
      </SectionWrapper>
      <SectionWrapper>
        {/* <SectionTitle>Meet The Team</SectionTitle> */}
      </SectionWrapper>
    </Wrapper>
  );
};

export default Home;
