import NationalScienceFoundation from './testimonials/NationalScienceFoundation.pdf';
import NationalScienceFoundationImage from './images/NationalScienceFoundation.jpeg';
import Dexcom from './testimonials/Dexcom.pdf';
import DexcomImage from './images/Dexcom.jpeg';
import CharlotteBlanck from './testimonials/CharlotteBlanck.pdf';
import CharlotteBlanckImage from './images/CharlotteBlanck.jpeg';
import MargaretBattin from './testimonials/MargaretBattin.pdf';

export const testimonials = [
  {
    name: 'Camp Seale Harris',
    quote:
      'It allows our medical staff to monitor blood sugars, insulin dosages, carbohydrate intake, etc., continuously - and in real time',
    file: NationalScienceFoundation,
    image: NationalScienceFoundationImage,
  },
  {
    name: 'Charlotte Blanck',
    quote:
      'With technology like CampViews, it is easier to let go and give your child the freedom and independence that every child deserves',
    file: CharlotteBlanck,
    image: CharlotteBlanckImage,
  },
  {
    name: 'Dexcom',
    quote:
      'CampViews will bring additonal support to camp partcipants by allowing real-time access to Dexcom CGM data',
    file: Dexcom,
    image: DexcomImage,
  },
  {
    name: 'Margaret Battin',
    subText: 'RN, BSN',
    quote:
      'It assists in the safety of our campers so that they can experience the best possible camp.',
    file: MargaretBattin,
    image: CharlotteBlanckImage,
  },
];
