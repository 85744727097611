import DailyMed from './images/DailyMeds.png';
import UserRoles from './images/UserRoles.png';
import CheckIn from './images/CheckIn.png';
import Dashboard from './images/Dashboard.png';
import Registration from './images/Registration.png';
import SiteChanges from './images/SiteChanges.png';

export const sellingPoints = [
  {
    header: 'Daily Meds',
    body: 'No more tracking down a camper or Medical Staff member to confirm if they have recieved their daily medication. Digital way to track and organize daily medications. Simple click of a button to document with a 24 hour reset.',
    img: DailyMed,
  },
  {
    header: 'Multiple User Types',
    body: [
      'Admin: Can add and/or edit anything on the platform',
      'Medial Staff: Can see and document all information',
      'Conselor Staff: Can document all information except dosing',
    ],
    img: UserRoles,
  },
  {
    header: 'Check In & Out',
    body: "Cut your check in time by 50% with a user friendly three step Check-In process. A standardized medical check-in with Dexcom Log In. Quick & simple two step Check-Out process. Full overview of each camper's session. Easily send a PDF logsheet to the campers guardian.",
    img: CheckIn,
  },
  {
    header: 'Realtime Dashboard',
    body: 'Integration with the Dexcom real time API. See all Dexcom users at camp on one screen and view all reading in real time for a safer camp!',
    img: Dashboard,
  },
  {
    header: 'Registration',
    body: 'All in one registration platform for all program sessions.',
    img: Registration,
  },
  {
    header: 'Site Changes',
    body: 'Easily schedule pump and CGM changes for the duration of each camp session during Check-In.',
    img: SiteChanges,
  },
];
