const faqs = [
  {
    question: "What if I can't log in?",
    answer:
      'A working internet connection and an active user account is required; passwords can be reset via the “forgot password” button.',
  },
  {
    question: 'Why cant I see all participant data?',
    answer:
      'Different user roles have access to different capabilities when accessing confidential/medical information.',
  },
  {
    question: 'Why does the Dashboard say “Last Fingerstick”?',
    answer:
      'This value reflects the most recent blood glucose level and its source; a manually documented fingerstick BG.',
  },
  {
    question: 'What does “S/R” on the Dashboard mean?',
    answer:
      '“Streaming” & “Rest” define the data access route currently used for the displayed BG value and “Streaming” will auto-populate new values.',
  },
  {
    question: 'What if I input data in the wrong section?',
    answer:
      'Clicking back into the section allows editing & re-saving depending on the user role or section limitations.',
  },
  // {
  //   question: 'This is a demo example question?',
  //   answer: 'This is a demo example answer.',
  // }
];

const pdfLinks = [
  {
    label: 'Views EMR User Guide (English)',
    link: 'https://drive.google.com/file/d/1NnOParRGtu06gOxZV8vkPHzFnBa0LZIa/preview',
  },
  // {
  //   label: 'example label for the second link',
  //   link: 'examplelink.com',
  // }
];

export { faqs, pdfLinks };
