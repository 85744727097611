import styled, { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import { colors } from '../../styles';

const Wrapper = styled.div`
  display: flex;
  background-color: ${colors.black};
  height: 50px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 500px) {
    position: fixed;
    top: 0;
    z-index: 100;
    left: 0;
    right: 0;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex: 1;
`;

const Link = styled(RouterLink)`
  color: ${colors.cream};
  text-decoration: none;

  :hover {
    color: ${colors.limeGreen};
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${colors.limeGreen};
    `}
`;

const Logo = styled.img`
  height: 40px;
  margin-left: 10px;
  @media (max-width: 600px) {
    display: none;
  }
`;

const LogoSpacePlaceholder = styled.div`
  width: 91.2px;

  @media (max-width: 600px) {
    display: none;
  }
`;

const ExternalLink = styled.a`
  color: ${({ color }) => colors[color] || colors.gray};
  text-decoration: none;

  :hover {
    color: ${({ color }) => color && colors.limeGreen};
  }
`;

export { Wrapper, Link, Logo, LinkWrapper, LogoSpacePlaceholder, ExternalLink };
