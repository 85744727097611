import * as React from 'react';

import NDALogoNew from '../../images/campLogos/NDA_Logo.png';
import CampHopeLogo from '../../images/campLogos/CampHope_Logo.png';
import CHHLogo from '../../images/campLogos/CHH_Logo.jpeg';
import CSLALogo from '../../images/campLogos/CSLA_Logo.png';
import MYDALogo from '../../images/campLogos/MYDA_Logo.png';

import { LinksWrapper } from '../Home/styled-components';

const camps = [
  {
    link: 'https://www.campviews.com/guest/signup.php?org=4',
    logo: NDALogoNew,
  },
  {
    link: 'https://www.campviews.com/guest/signup.php?org=20',
    logo: CampHopeLogo,
  },
  {
    link: 'https://www.campviews.com/guest/signup.php?org=34',
    logo: CHHLogo,
  },
  {
    link: 'https://www.campviews.com/guest/signup.php?org=44',
    logo: CSLALogo,
  },
  {
    link: 'https://www.campviews.com/guest/signup.php?org=32',
    logo: MYDALogo,
  },
];

const Register = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: 50,
        overflow: 'scroll',
        minHeight: 'calc(100vh - 50px - 180px)',
      }}
    >
      <h1 style={{ fontSize: 30, textAlign: 'center' }}>
        Click on your camp's logo to register
      </h1>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {camps.map((camp) => (
          <img
            src={camp.logo}
            alt="Camp Logo"
            style={{
              width: 'auto',
              height: 'auto',
              margin: 20,
              maxWidth: 350,
              cursor: 'pointer',
            }}
            onClick={() => window.open(camp.link, '_blank')}
          />
        ))}
      </div>
      <LinksWrapper></LinksWrapper>
    </div>
  );
};

export default Register;
