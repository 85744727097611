import styled from 'styled-components';
import { colors } from '../../styles';

const SellingPointsContainer = styled.div`
  > h1 {
    font-weight: 300;
    font-size: 24px;
    color: ${colors.black};
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-color: ${colors.limeGreen};
  }
  font-size: 18px;
  color: ${colors.gray};
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  -webkit-flex: 0 1 33%;
  flex-basis: calc(33% - 40px);

  @media (max-width: 900px) {
    flex-basis: calc(50% - 40px);
  }
  @media (max-width: 600px) {
    flex-basis: calc(100% - 40px);
  }

  > img {
    width: 100%;
    height: auto;
  }
`;

const SellingPointsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  flex-wrap: wrap;
  margin: 40px;
  max-width: 90%;
`;

export { SellingPointsContainer, SellingPointsWrapper };
